// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_appsync_graphqlEndpoint:
    "https://rsjoqlte7fgirfbhfr3tuinwie.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-wkhoutbtqnhl3bbwf2fl3aexhq",
  aws_content_delivery_bucket: "loftradio-20191005163833-hostingbucket",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_url: "https://d1l4iw4o4jstt0.cloudfront.net",
};

export default awsmobile;
