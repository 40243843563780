
import Vue from "vue";
import Vuex from "vuex";
import ethers from "./ethers/index"
import actions from './actions'
import mutations from './mutations'

import { DEFAULT_THEMES } from './constants/themes'
import { LOADING_LISTENER, LOADING_SONG } from './constants/music'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ethers
  },
  actions,
  mutations,
  state: {
    chat: {
      displayChat: false,
      firstRender: false,
      unreadNotification: false,
    },
    currentSong: LOADING_SONG,
    explore: {
      displayExplore: false,
    },
    listener: LOADING_LISTENER,
    liveTips: [],
    pendingTips: [],
    showcase: {},
    theme: {
      availableThemes: DEFAULT_THEMES,
      showcaseThemes: [],
      currentThemeIndex: 1,
      srcMobile: "",
      srcDesktop: "",
    },
    tip: {
      amountEth: null,
      amountUsd: 0.50,
      artistId: "",
      artistName: "",
      artistUrl: "",
      songId: "",
      songTitle: "",
      walletAddress: "",
    },
    tipReward: false,
    tipSubscriptionReward: false,
    toastMessage: {
      display: false,
      message: null,
    }
  },
  getters: {
    currentTheme: state => state.theme.availableThemes[state.theme.currentThemeIndex],
  }
});

export default store;
