/* eslint-disable */
export const DEFAULT_THEMES = [
  {
    name: "gradient",
    sourceDesktop: null,
    sourceDesktopMp4: null,
    sourceMobile: null,
    sourceMobileMp4: null,
  },
  {
    name: "lighthouse",
    sourceDesktopWebm: null,
    sourceDesktopMp4: "https://storage.googleapis.com/loft-bg-1/Zennyan_8.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4:
      "https://storage.googleapis.com/loft-bg-1/Zennyan_Mobile.mp4",
  },
  {
    name: "mood",
    sourceDesktopWebm: null,
    sourceDesktopMp4: "https://storage.googleapis.com/loft-bg-2/mood.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4: "https://storage.googleapis.com/loft-bg-2/mood.mp4",
  },
  {
    name: "neon",
    sourceDesktopWebm: null,
    sourceDesktopMp4: "https://storage.googleapis.com/loft-bg-2/neon.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4: "https://storage.googleapis.com/loft-bg-2/neon-mobile.mp4",
  },
];

export const SHOWCASE_THEMES = [
  {
    name: "gradient",
    sourceDesktop: null,
    sourceMobile: null,
  },
  {
    name: "showcase-trippy-bg-1",
    sourceDesktopWebm: null,
    sourceDesktopMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-1.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-1.mp4",
  },
  {
    name: "showcase-trippy-bg-2",
    sourceDesktopWebm: null,
    sourceDesktopMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-2.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-2.mp4",
  },
  {
    name: "showcase-trippy-bg-3",
    sourceDesktopWebm: null,
    sourceDesktopMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-6.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4:
      "https://storage.googleapis.com/showcase-bgs/omariOfficial-6.mp4",
  },
  {
    name: "showcase-album-art",
    sourceDesktopWebm: null,
    sourceDesktopMp4:
      "https://storage.googleapis.com/showcase-bgs/omari-jazz-4.mp4",
    sourceMobileWebm: null,
    sourceMobileMp4:
      "https://storage.googleapis.com/showcase-bgs/omari-jazz-4.mp4",
  },
];
