export default {
  toggleShowcase({ commit, state }) {
    if (!state.showcase.display) {
      commit("setCurrentThemeIndex", 0);
      commit("setShowcase", {
        ...state.showcase,
        display: true,
        displayCountdown: false
      });
    } else {
      commit("resetAvailableThemes");
      commit("setRandomTheme");
      commit("setShowcase", {
        ...state.showcase,
        display: false,
        displayCountdown: true
      });
      // TODO - destroy current video && play default one
    }

  },
  pushLiveTip({ commit }, tip) {
    commit("pushLiveTip", tip);
    commit("setTipSubscriptionReward", true);

    setTimeout(() => {
      commit("popLiveTip");
      commit("setTipSubscriptionReward", false);
    }, 20000);
  },
  showGetWalletAndTipMsg({ dispatch, commit }) {
    commit("showToastMessage", "get-wallet-and-tip");
    dispatch("resetToastMessage", 6000);
  },
  showLowFundsMsg({ dispatch, commit }) {
    commit("showToastMessage", "Funds too low - Minimum tip: $0.50");
    dispatch("resetToastMessage", 6000);
  },
  showMetaMaskMsg({ dispatch, commit }) {
    commit("showToastMessage", "get-wallet");
    dispatch("resetToastMessage", 6000);
  },
  showTipForCustomNameMsg({ dispatch, commit }) {
    commit("showToastMessage", "Send a tip to unlock custom names 😉");
    dispatch("resetToastMessage", 6000);
  },
  resetToastMessage({ commit }, duration) {
    let delay = duration || 0;
    setTimeout(() => {
      commit("resetToastMessage");
    }, delay);
  }
}