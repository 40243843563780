export default {
  toggleShowcase(state) {
    state.showcase.display = !state.showcase.display;
  },
  killShowcase(state) {
    state.showcase.display = false;
  },
  cycleCurrentTheme(state) {
    if (
      state.theme.currentThemeIndex ===
      state.theme.availableThemes.length - 1
    ) {
      state.theme.currentThemeIndex = 0;
    } else state.theme.currentThemeIndex++;
  },
  generateListenerName(state) {
    if (!state.listener.name) {
      let listenerNum = Math.floor(Math.random() * 1001);

      switch (listenerNum) {
        case 1:
          listenerNum += " 🏆";
          break;
        case 8:
          listenerNum += " 🎱";
          break;
        case 69:
          listenerNum += " ♋️";
          break;
        case 100:
          listenerNum += " 💯";
          break;
        case 404:
          listenerNum += " 📼";
          break;
        case 420:
          listenerNum += " 💨";
          break;
        case 666:
          listenerNum += " 👹";
          break;
        case 710:
          listenerNum += " 🍯";
          break;
        case 777:
          listenerNum += " 🎰";
          break;
        case 808:
          listenerNum += " 🔊";
          break;
        case 911:
          listenerNum += " 👮‍♀️";
          break;
        case 1000:
          listenerNum += " 💖";
          break;
      }

      state.listener.name = "listener." + listenerNum
    }
  },
  popLiveTip(state) {
    state.liveTips.shift();
  },
  pushLiveTip(state, tip) {
    state.liveTips.push(tip);
  },
  addPendingTip(state, tip) {
    state.pendingTips.push(tip);
  },
  removePendingTip(state) {
    state.pendingTips.pop();
  },
  resetToastMessage(state) {
    state.toastMessage = {
      display: false,
      message: null,
    };
  },
  resetAvailableThemes(state) {
    state.theme.availableThemes = DEFAULT_THEMES
  },
  setCurrentSong(state, currentSong) {
    state.currentSong = currentSong;

    // let now = new Date().getTime();
    // state.showcase.displayCountdown = (now > state.showcase.countdownStart && now < state.showcase.start);
    // // state.showcase.display = (now > state.showcase.start && now < state.showcase.end);

    // if (now > state.showcase.start && now < state.showcase.end) {
    //   state.showcase.display = true;
    //   state.showcase.displayCountdown = false;
    // } else if (now > state.showcase.end) {
    //   state.showcase.display = false;
    // }
    // // state.showcase.display = (now > state.showcase.start && now < state.showcase.end && state.currentSong.artist === state.showcase.artist);
  },
  setCurrentThemeIndex(state, index) {
    state.theme.currentThemeIndex = index;
  },
  setDisplayChat(state, displayChat) {
    if (displayChat) state.chat.unreadNotification = false;
    state.chat.displayChat = displayChat;
  },
  setDisplayExplore(state, displayExplore) {
    state.explore.displayExplore = displayExplore;
  },
  setFirstRender(state) {
    state.chat.firstRender = true;
  },
  setIsMobile(state, isMobile) {
    state.listener.isMobile = isMobile;
  },
  incrementListenerTips(state) {
    state.listener = {
      ...state.listener,
      numTips: state.listener.numTips++
    }
  },
  setListener(state, listener) {
    state.listener = listener;
  },
  setListenerDevice(state, device) {
    state.listener.device = device;
  },
  setListenerName(state, name) {
    state.listener.name = name;
  },
  setRandomTheme(state) {
    state.theme.currentThemeIndex = Math.floor(
      Math.random() * state.theme.availableThemes.length
    );
  },
  setShowcase(state, showcase) {
    state.showcase = showcase;
  },
  setTip(state, tip) {
    state.tip = tip;
  },
  setTipSubscriptionReward(state, reward) {
    state.tipSubscriptionReward = reward;
  },
  setUnreadNotification(state, unreadNotification) {
    state.chat.unreadNotification = unreadNotification;
  },
  showToastMessage(state, message) {
    state.toastMessage = {
      display: true,
      message: message,
    };
  }
}