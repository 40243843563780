import gql from "graphql-tag";
/* eslint-disable */
export default gql `
mutation CreateUser($name: String!, $walletAddress: String!) {
    createUser(input: {
        name: $name,
        walletAddress: $walletAddress,
        totalAmountDonated: 0.0,
        totalAmountReceived: 0.0
    }) {
        id
        name
        walletAddress
    }
}
`