<script>
import { mapState } from "vuex";
import Home from "./views/Home";

export default {
  name: "app",
  components: {
    // Home: () => import("./views/Home"),
    Home,
    Entry: () => import("./views/Entry"),
    Explore: () => import("./views/Explore")
  },
  computed: mapState({
    isMobile: state => state.listener.isMobile,
    displayExplore: state => state.explore.displayExplore,
    pendingTips: "pendingTips"
  }),
  data: () => ({
    locked: true
  }),
  methods: {
    checkScreenSize() {
      if (window.innerWidth <= 650 && !this.isMobile) {
        this.$store.commit("setIsMobile", true);
      } else if (window.innerWidth >= 650 && this.isMobile) {
        this.$store.commit("setIsMobile", false);
      }
    },
    closeExplore() {
      this.$store.commit("setDisplayExplore", false);
    },
    enterLoft() {
      this.locked = false;
      this.$refs.Home.$refs.StationBackground.$refs.backgroundVideo.play();
      this.$refs.Home.$refs.HomeHeader.$refs.RadioPlayer.play();
      this.$refs.Home.$refs.HomeHeader.$refs.RadioPlayer.updateVolume();
      this.$refs.Home.enableKeyboardShortcuts();
      this.$refs.Home.loadingTimeout();
    },
    setDevice() {
      var userAgent = (
        navigator.userAgent ||
        navigator.vendor ||
        window.opera
      ).toLowerCase();

      let rotatableDevice =
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone|iemobile/i.test(userAgent)) {
        this.$store.commit("setListenerDevice", "windows");
      } else if (/android/i.test(userAgent)) {
        this.$store.commit("setListenerDevice", "android");
      } else if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
        this.$store.commit("setListenerDevice", "ios");
      } else if (/blackberry/i.test(userAgent)) {
        this.$store.commit("setListenerDevice", "blackberry");
      } else if (/kindle/i.test(userAgent)) {
        this.$store.commit("setListenerDevice", "kindle");
      } else if (!rotatableDevice) {
        this.$store.commit("setListenerDevice", "desktop");
      }
    },
    setupExplore() {
      this.$refs.ExploreOuter.scrollToTop();
      this.$refs.ExploreOuter.$refs.Artists.scrollToTop();

      if (
        !this.$refs.ExploreOuter.$refs.Artists ||
        this.$refs.ExploreOuter.$refs.Artists.artists.length < 5
      ) {
        this.$refs.ExploreOuter.$refs.Artists.getArtistList();
      }
    }
  },
  created() {
    // this.$store.commit("setRandomTheme");
    this.$store.commit("generateListenerName");
  },
  async mounted() {
    this.setDevice();
    this.$store.commit("setIsMobile", window.innerWidth <= 650);

    //Mobile viewport fix
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", this.checkScreenSize);

    if (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      console.warn("Safari users cannot tip");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  }
};
</script>

<template>
  <div class="app">
    <transition name="medium-fade">
      <Entry v-if="locked" @unlock="enterLoft" />
    </transition>

    <Home v-show="!locked" ref="Home" @setupExplore="setupExplore()" />
    <Explore ref="ExploreOuter" v-show="displayExplore" @close="closeExplore()" />
  </div>
</template>

<style lang="scss">
@import "./styles/global.scss";
@import "./styles/transitions.scss";
@import url("https://use.typekit.net/ijc3kul.css");

@font-face {
  font-family: "vcr";
  src: url("./assets/fonts/VCR_OSD_MONO_1.001.ttf");
}
@font-face {
  font-family: "monument";
  src: url("./assets/fonts/MonumentExtended-Regular.otf");
}
@font-face {
  font-family: "vtf";
  src: url("./assets/fonts/Mister Pixel Regular.otf");
}
@font-face {
  font-family: "lunchType24";
  src: url("./assets/fonts/lunchtype24-regular-expanded-webfont.woff");
}

a:focus,
textarea:focus,
input:focus,
button:focus {
  outline: none;
}

.app {
  z-index: -1000;
  font-family: "neue-haas-unica", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  // height: calc(var(--vh, 1vh) * 100);
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0;
}
</style>
