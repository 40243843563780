import Vue from "vue";
import App from "./App.vue";
import store from "./store/store.js";
import VueApollo from "vue-apollo";
import AWSAppSyncClient from "aws-appsync";
import VueTypedJs from "vue-typed-js";
import appSyncConfig from "./aws-exports";

// Vue.config.devtools = true;
// Vue.config.performance = true;
Vue.config.productionTip = false;

const config = {
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_project_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    apiKey: "da2-taqmb2vqkbcazniuyy6ghjwu4u",
  },
};

const options = {
  defaultOptions: {
    watchQuery: {
      // fetchPolicy: "cache-and-network"
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
};

const client = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({
  defaultClient: client,
});

Vue.use(VueApollo);
Vue.use(VueTypedJs);

new Vue({
  provide: apolloProvider.provide(), // keep this .provide()
  store,
  render: (h) => h(App),
}).$mount("#app");

store.dispatch("ethers/init");
