<script>
import RadioPlayer from "./RadioPlayer";
export default {
  name: "HomeHeader",
  components: {
    ExploreToggle: () => import("./ExploreToggle"),
    RadioPlayer
    // RadioPlayer: () => import("./RadioPlayer"),
  },
  computed: {
    isMobile() {
      return this.$store.state.listener.isMobile;
    }
  },
  methods: {
    setupExplore() {
      this.$emit("setupExplore");
    }
  }
};
</script>

<template>
  <div :class="[{ mobile: isMobile }, 'home-header']">
    <ExploreToggle
      id="explore-toggle"
      ref="ExploreToggle"
      key="header"
      @setupExplore="setupExplore()"
    />
    <div id="desktop-spacer"></div>
    <RadioPlayer id="radio-player" ref="RadioPlayer" />
  </div>
</template>

<style lang="scss" scoped>
@import "../../../styles/global.scss";

.home-header {
  z-index: 101;
  @extend %non-selectable;
  padding: 15px 15px;
  display: flex;

  #desktop-spacer {
    flex: 1 1 auto;
    text-align: center;
    height: 59px;
  }

  #radio-player {
    min-width: 200px;
    text-align: right;
  }
}

.mobile {
  #explore-toggle {
    touch-action: auto;
    flex: 1 1 auto;
    text-align: center;
    height: 59px;
  }
}
</style>
