import gql from "graphql-tag";
/* eslint-disable */
export default gql`
    query listSongs($song: String!, $artist: String!) {
      listSongs(filter: {
        title: { eq: $song },
        artist: { eq: $artist }
      }, limit: 1000) {
        items {
          id
          artistObject {
            id
            walletAddress
            url
        }
      }
    }
  }
`