<script>
import HomeHeader from "../components/Home/Header/HomeHeader";
import CREATE_USER from "../graphql/mutations/create_user.js";
import LIST_USERS from "../graphql/queries/list_users.js";

import { mapState } from "vuex";

const promisify = inner =>
  new Promise((resolve, reject) =>
    inner((err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    })
  );

export default {
  name: "Home",
  components: {
    ArtistHyperlink: () => import("../components/Home/Main/ArtistHyperlink"),
    CurrentSong: () => import("../components/Home/Main/CurrentSong"),
    Dialogue: () => import("../components/Home/Main/Dialogue"),
    FourTwenty: () => import("../components/Home/FourTwenty"),
    HomeFooter: () => import("../components/Home/Footer/HomeFooter"),
    HomeHeader,
    NetworkInfo: () => import("../components/Home/Main/NetworkInfo"),
    ShowcaseArt: () => import("../components/Home/Showcase/ShowcaseArt"),
    StationBackground: () =>
      import("../components/Home/Backgrounds/StationBackground"),
    TipButton: () => import("../components/Home/Main/TipButton"),
    ToastMessage: () => import("../components/ToastMessage")
  },
  apollo: {
    user: {
      query: LIST_USERS,
      skip: true,
      variables() {
        return {
          walletAddress: this.listener.walletAddress.toLowerCase()
        };
      },
      update: data => data.listUsers,
      result() {
        this.$apollo.queries.user.stopPolling();
      },
      error(e) {
        console.error(e);
        this.$apollo.queries.user.refetch();
      }
    }
  },
  watch: {
    userWalletAddress() {
      // this.$store.dispatch("getBalance");
      this.getUser();
      this.$store.dispatch("ethers/getBalance");
    }
  },
  computed: {
    ...mapState({
      userWalletAddress: state => state.ethers.address,
      balanceEth: state => state.ethers.balanceEth,
      connected: state => state.ethers.connected,
      currentSong: "currentSong",
      // displayShowcase: state => state.showcase.display,
      displayExplore: state => state.explore.displayExplore,
      displayChat: state => state.chat.displayChat,
      ethPrice: state => state.ethers.ethPriceUsd,
      isMobile: state => state.listener.isMobile,
      listener: "listener",
      network: state => state.ethers.network,
      pendingTips: "pendingTips",
      tip: "tip"
    }),
    currentTheme() {
      return this.$store.getters.currentTheme.name;
    },
    showNetworkInfo() {
      return this.connected && this.network !== "Mainnet";
    },
    usdBalance() {
      const balanceETH = this.balanceEth;
      const ethPrice = this.ethPrice;
      return (balanceETH * ethPrice).toFixed(2);
    }
  },
  methods: {
    createUser() {
      let addr = this.listener.walletAddress;
      this.$apollo
        .mutate({
          mutation: CREATE_USER,
          variables: {
            name: "listener." + addr.substring(addr.length - 4, addr.length),
            walletAddress: this.listener.walletAddress.toLowerCase()
          }
        })
        .then(response => {
          store.commit("setListener", {
            ...this.listener,
            id: response.data.createUser.id,
            name: response.data.createUser.name,
            loggedIn: true
          });
        })
        .catch(error => console.error("createUserError: ", error));
    },
    enableKeyboardShortcuts() {
      document.onkeydown = e => {
        // * ENABLED WHEN CHAT IS CLOSED *
        if (!this.displayChat) {
          // SHIFT + ...
          if (e.shiftKey) {
            // ENTER
            if (e.keyCode === 13) this.$refs.TipButton.incrementTip();
            // SPACE
            else if (e.keyCode === 32)
              this.$refs.HomeHeader.$refs.RadioPlayer.toggleMute();
            // UP || RIGHT ARROW
            else if (e.keyCode === 38 || e.keyCode === 39)
              this.$refs.HomeHeader.$refs.RadioPlayer.updateVolume("up");
            // DOWN || LEFT ARROW
            else if (e.keyCode === 37 || e.keyCode === 40)
              this.$refs.HomeHeader.$refs.RadioPlayer.updateVolume("down");
            // B
            else if (e.keyCode === 66) this.cycleTheme();
            // C
            else if (e.keyCode === 67)
              this.$refs.Footer.$refs.ChatToggle.toggleChat();
            // E
            else if (e.keyCode === 69) {
              this.displayExplore
                ? this.$store.commit("setDisplayExplore", !this.displayExplore)
                : this.$refs.HomeHeader.$refs.ExploreToggle.showExplore();
            }
          }
          // ESC
          if (e.keyCode === 27) this.$store.commit("setDisplayExplore", false);
        } else {
          // * ENABLED WHEN CHAT IS OPEN *
          // ENTER
          if (e.keyCode === 13) {
            this.$refs.Footer.$refs.ChatToggle.$refs.Chat.$refs.MessageInput.createMessage();
          }
          // ESC
          else if (e.keyCode === 27)
            this.$refs.Footer.$refs.ChatToggle.toggleChat();
        }
      };
    },
    getUser() {
      this.$apollo
        .query({
          query: LIST_USERS,
          variables: {
            walletAddress: this.userWalletAddress.toLowerCase()
          },
          update: data => data.listUsers
        })
        .then(response => {
          if (
            response.data &&
            response.data.listUsers &&
            response.data.listUsers.items.length > 0
          ) {
            this.$store.commit("setListener", {
              ...this.listener,
              id: response.data.listUsers.items[0].id,
              name: response.data.listUsers.items[0].name,
              numTips: response.data.listUsers.items[0].tipsSent.items.length,
              walletAddress: this.userWalletAddress.toLowerCase(),
              loggedIn: true
            });
          } else this.createUser();
        })
        .catch(error => {
          console.error("getUser: ", error);
          this.$apollo.queries.user.startPolling(1000);
        });
    },
    loadingTimeout() {
      setTimeout(() => {
        if (this.currentSong.artist === "Loading music...") {
          this.$store.commit("setCurrentSong", {
            title: "Loading song...",
            artist: "Loading artist..."
          });
        }
      }, 3000);
    },
    cycleTheme() {
      this.$store.commit("cycleCurrentTheme");

      if (this.currentTheme !== "gradient")
        this.$refs.StationBackground.$refs.backgroundVideo.load();
    },
    setupExplore() {
      this.$emit("setupExplore");
    }
  }
};
</script>

<template>
  <div :class="[{ mobile: isMobile }, currentTheme, 'home']">
    <div class="home-content">
      <HomeHeader ref="HomeHeader" @setupExplore="setupExplore()" />
      
      <div class="middle-section" v-show="!displayExplore">
        <div class="main">
          <Dialogue />
          <CurrentSong :title="currentSong.title" :is-mobile="isMobile" />
          <ArtistHyperlink :url="currentSong.artistUrl" :artist-name="currentSong.artist"></ArtistHyperlink>

          <TipButton ref="TipButton" v-show="!(isMobile && displayChat)" />

          <NetworkInfo v-if="showNetworkInfo" :network="network" :is-mobile="isMobile" />
        </div>

        <ShowcaseArt v-if="!isMobile" />
      </div>

      <HomeFooter ref="Footer" @cycleTheme="cycleTheme" />
    </div>

    <transition name="medium-fade">
      <FourTwenty />
    </transition>

    <ToastMessage />
    <StationBackground ref="StationBackground" />
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/hoverstates.scss";
@import "../styles/themes/colors.scss";
@import "../styles/themes/themes.scss";

.home {
  z-index: 10;
  overflow: none;
  color: $loft-black;
  font-size: 14px;
  height: 100%;

  touch-action: none;
}

.home-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  padding-bottom: 15vh;
  text-align: left;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2 2 content;
  width: 35vw;
}

.middle-section {
  margin: 0px 13vw 0px 13vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 2 auto;
}

.mobile {
  .home-content {
    height: calc(var(--vh, 1vh) * 100);
  }

  .main {
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    width: 85vw;
  }

  .middle-section {
    margin: 0px 5vw 0px 5vw;
  }

  #footer-cycle-chat {
    width: 100%;
  }
}

.medium-fade-enter-active,
.medium-fade-leave-active {
  transition: opacity 1s;
}
.medium-fade-enter,
.medium-fade-leave-to {
  opacity: 0;
}
</style>