import gql from "graphql-tag";
export default gql`
  query ListUsers($walletAddress: String!) {
    listUsers(filter: { walletAddress: { eq: $walletAddress } }, limit: 1000) {
      items {
        id
        name
        walletAddress
        totalAmountDonated
        tipsSent {
          items {
            id
            amount
          }
        }
      }
    }
  }
`;
