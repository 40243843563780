<script>
/* eslint-disable */
import LIST_SONGS from "../../../graphql/queries/list_songs.js";
import gql from "graphql-tag";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentSong: "currentSong",
      isMobile: state => state.listener.isMobile
    }),
    showVolumeSlider() {
      const isMobile = this.isMobile;
      const hoveringVolumeSlider = this.hoveringVolumeSlider;
      const hoveringVolumeButton = this.hoveringVolumeButton;
      return !isMobile && (hoveringVolumeSlider || hoveringVolumeButton);
    }
  },
  data: () => ({
    cachedVolume: 50,
    hoveringVolumeButton: false,
    hoveringVolumeSlider: false,
    muted: false,
    showMobileVolumeSlider: false,
    volume: 50
  }),
  apollo: {
    songs: {
      query: LIST_SONGS,
      skip: true,
      variables() {
        return {
          song: this.currentSong.title,
          artist: this.currentSong.artist
        };
      },
      update: data => data.listSongs.items,
      result() {
        this.$apollo.queries.songs.stopPolling();
      },
      error(e) {
        console.error("ListSongs init issue 😠 - retrying", e);
        // this.$apollo.queries.songs.startPolling(1000);
        this.$apollo.queries.songs.refetch();
      }
    }
  },
  methods: {
    listSong(trackInfo) {
      this.$apollo
        .query({
          query: LIST_SONGS,
          variables: {
            song: trackInfo[1],
            artist: trackInfo[0]
          },
          update: data => data.listSongs.items
        })
        .then(response => {
          this.$store.commit("setCurrentSong", {
            id: response.data.listSongs.items[0].id,
            title: trackInfo[1],
            artist: trackInfo[0],
            artistId: response.data.listSongs.items[0].artistObject.id,
            artistUrl: response.data.listSongs.items[0].artistObject.url,
            walletAddress:
              response.data.listSongs.items[0].artistObject.walletAddress
          });
        })
        .catch(error => {
          console.error("listSong() issue 😡 - retrying", error);
          this.$apollo.queries.songs.startPolling(1000);
          // this.$apollo.queries.songs.refetch();
        });
    },
    mouseOver() {
      if (!this.isMobile) this.hoveringVolumeButton = true;
    },
    mouseLeave() {
      if (!this.isMobile) this.hoveringVolumeButton = false;
    },
    play() {
      document.dispatchEvent(new Event("play"));
    },
    toggleMute() {
      // if (this.isMobile) {
      //   this.showMobileVolumeSlider = !this.showMobileVolumeSlider;
      // } else {
      // this.$emit("toggle-mute");
      document.dispatchEvent(new Event("toggle-mute"));
      // }
    },
    updateVolume(direction) {
      if (direction === "up") {
        if (this.volume <= 45) this.volume += 5;
        else this.volume = 50;
      } else if (direction === "down") {
        if (this.volume >= 5) this.volume -= 5;
        else this.volume = 0;
      }
      document.dispatchEvent(new Event("update-volume"));
    }
  },
  mounted() {
    var player = $(".radioplayer").radiocoPlayer();

    player.event("songChanged", event => {
      if (
        event.trackTitle !== event.previousTrack &&
        event.trackTitle !== "♪(๑ᴖ◡ᴖ๑)♪ (・ω・｀) (*´∇｀*)" &&
        event.trackTitle !== "Loading song..."
      ) {
        this.listSong(event.trackTitle.split(" - "));
      }
    });

    player.event("streamInfoError", event => {
      console.error("Radio.co stream error 🤬");
    });

    player.event("audioLoaded", event => {
      // console.log("audio stream loaded");
    });


    document.addEventListener(
      "play",
      () => {
        player.play();
      },
      false
    );

    document.addEventListener(
      "toggle-mute",
      () => {
        if (this.muted) {
          this.muted = false;
          player.volume(this.cachedVolume * 2);
          this.volume = this.cachedVolume;
        } else {
          this.muted = true;
          this.cachedVolume = this.volume;
          this.volume = 0;
          player.mute();
        }
      },
      false
    );

    document.addEventListener(
      "update-volume",
      () => {
        player.volume(this.volume * 2);
        this.muted = this.volume == 0;
      },
      false
    );

    this.$refs.volIconContainer.addEventListener("touchstart", () => {}, true);
  },
  beforeDestroy() {
    document.removeEventListener("play");
    document.removeEventListener("toggle-mute");
    document.removeEventListener("update-volume");
    this.$refs.volIconContainer.removeEventListener(
      "touchstart",
      () => {},
      true
    );
  }
};
</script>

<template>
  <div id="RadioPlayer" ref="radio">
    <div
      class="vol-icon-container"
      ref="volIconContainer"
      :class="{ volIconDesktop: !isMobile }"
      @mouseover="mouseOver()"
      @mouseleave="mouseLeave()"
    >
      <a
        @click="toggleMute"
        class="vol-icon"
        id="vol-icon-mute"
        v-if="muted || volume == 0"
        draggable="false"
      />

      <a
        @click="toggleMute"
        class="vol-icon"
        id="vol-icon-low"
        v-else-if="!muted && volume < 28"
        draggable="false"
      />

      <a @click="toggleMute" class="vol-icon" id="vol-icon-high" v-else draggable="false" />

      <!-- <transition name="fast-fade"> -->
      <div
        class="vol-slider"
        @mouseover="mouseOver()"
        @mouseleave="mouseLeave()"
        v-show="showVolumeSlider"
      >
        <input
          class="vol-slider-input"
          type="range"
          min="0"
          max="50"
          v-model="volume"
          :oninput="updateVolume()"
        />
      </div>
      <!-- </transition> / -->
    </div>

    <div
      class="radioplayer"
      data-src="https://s2.radio.co/s1abad15db/listen"
      data-autoplay="false"
      data-volumeslider="false"
      data-playbutton="false"
      data-elapsedtime="false"
      data-nowplaying="false"
      data-showplayer="false"
      data-showartwork="false"
      data-showimage="false"
    ></div>
  </div>
</template>

<style lang="scss">
@import "../../../styles/hoverstates.scss";
@import "../../../styles/sliders.scss";
@import "../../../styles/themes/themes.scss";
@import "../../../styles/transitions.scss";

#RadioPlayer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
}

.vol-icon-container {
  position: relative;
  border-radius: 8px;
  max-width: 59px;
  float: right;
  cursor: pointer;

  .vol-icon {
    @extend %home-button;
    @extend %non-selectable;
    height: 25px;
    width: 25px;
    touch-action: auto;
    display: inline-block;
    vertical-align: middle;

    @include themify($themes) {
      color: themed("station-btn-color");
      background-color: themed("station-btn-bg-color");
      border: themed("station-btn-border");
      backdrop-filter: themed("station-btn-backdrop-filter");

      &:active {
        color: themed("wallet-info-color-active");
        backdrop-filter: themed("station-btn-backdrop-active");
      }
    }
  }

  @include themify($themes) {
    #vol-icon-mute {
      content: themed("station-btn-icon-vol-mute");
    }
    #vol-icon-low {
      content: themed("station-btn-icon-vol-low");
    }
    #vol-icon-high {
      content: themed("station-btn-icon-vol-high");
    }
  }

  .vol-slider {
    top: 69px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;

    // right: 25px;
    width: 15px;
    height: 165px;
    // border: 2px solid rgba(20, 20, 20, 1);
    border-radius: 1px;
    // padding: 5px;
    margin: 0;
    z-index: 500;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .vol-slider-input {
      padding: 50px 9px;
      transform: rotate(270deg) translate(2px, 0px);
      transform-origin: center;
    }

    @include themify($themes) {
      background-color: themed("vol-slider-bg-color");
      border: themed("vol-slider-border");
    }
  }
}

.volIconDesktop {
  @include themify($themes) {
    &:hover {
      color: themed("wallet-info-color-hover");
      backdrop-filter: themed("station-btn-backdrop-hover");
    }
  }
}
</style>
