export const LOADING_LISTENER = {
  id: null,
  balanceETH: null,
  device: "unknown",
  isMobile: null,
  loggedIn: false,
  name: null,
  numTips: null,
  walletAddress: null
};

export const LOADING_SONG = {
  artist: "Loading music...",
  artistId: "",
  artistUrl: "#",
  id: "",
  title: "♪(๑ᴖ◡ᴖ๑)♪ (・ω・｀) (*´∇｀*)",
  walletAddress: ""
};
